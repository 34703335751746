import 'aos/dist/aos.css';

import React from 'react';

import AOS from 'aos';
import { Link } from 'gatsby';

import style from '../assets/css/main.module.css';
import pageStyle from '../assets/css/page.module.css';
import IMGGroupMobile from '../assets/img/about/Cover-mobile.jpg';
import IMGGroup from '../assets/img/about/Cover.jpg';

import IMGLaura from '../assets/img/team/laura.jpg';
import IMGLisa from '../assets/img/team/lisa.jpg';
import IMGMathieu from '../assets/img/team/mathieu_ds.jpg';
import IMGValentin from '../assets/img/team/valentin.jpg';
import IMGAlessio from '../assets/img/team/alessio.jpg';
import IMGAnissa from '../assets/img/team/anissa.jpg';

import confidence from '../assets/img/values/confidence.png';
import efficience from '../assets/img/values/efficience.png';
import proteam from '../assets/img/values/proteam.png';
import transparency from '../assets/img/values/transparency.png';
import AboutCard from '../components/about.card';
import Footer from '../components/footer';
import Hamburger from '../components/hamburger';
import HeroPage from '../components/hero.page';
import Menu from '../components/menu';
import Profile from '../components/profile';
import JobApply from '../components/profilemissing';
import SEO from '../components/seo';

class About extends React.Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return (
      <div id="top">
        <Menu />
        <SEO
          title="À propos | Agence digitale | MDS Digital Agency"
          description=""
          keywords="a propos"
        />
        <div className={style.menu_mobile}>
          <Hamburger />
        </div>

        <HeroPage specialClass={"about"} img={IMGGroup} imgMobile={IMGGroupMobile} position="top" />

        <article className={pageStyle.article} style={{ paddingBottom: '50px' }}>
          <h2 className={pageStyle.header_uppercase}>À propos</h2>

          <div className={style.about_values_container}>
            <AboutCard name="Confiance" img={confidence} />
            <AboutCard name="Transparence" img={transparency} />
            <AboutCard name="Efficience" img={efficience} />
            <AboutCard name="Équipe professionnelle" img={proteam} />
          </div>

          <div className={style.profile_flex}>
            <Profile
              picture={IMGMathieu}
              name="Mathieu"
              position="Founder & CEO"
              animeOrder="0"
              mail="mathieu"
            />
            <Profile
              picture={IMGLaura}
              name="Laura"
              position="Chief Operating Officer"
              animeOrder="1"
              mail="laura"
            />
            <Profile
              picture={IMGLisa}
              name="Lisa"
              position="Lead Web Integrator"
              animeOrder="2"
            />
            <Link className={style.about_job_link} to="/jobs/">
              <div className={style.about_job} data-aos="fade-up" data-aos-delay="500">
                <JobApply />
              </div>
            </Link>
          </div>
        </article>

        <Footer />
      </div>
    );
  }
}

export default About;
